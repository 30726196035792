import React from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import moment from 'moment'
import { connect } from 'react-redux'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import AppLayout from 'layouts/app-layout'
import AuthLayout from 'layouts/auth-layout'
import AdminLayout from 'layouts/admin-layout'
import DefaultLayout from 'layouts/default-layout'
import AppLocale from 'lang'
import { ADMIN_PREFIX_PATH, APP_PREFIX_PATH, AUTH_PREFIX_PATH, DEFAULT_PREFIX_PATH } from 'configs/AppConfig'
import IntlGlobalProvider from 'lang/IntlGlobalProvider'

import { useLanguage } from 'contexts/LanguageContext'

const RouteInterceptor = ({ children, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: AUTH_PREFIX_PATH,
            state: { from: location },
          }}
        />
      )
    }
  />
)

export const Views = (props) => {
  const { location, token } = props
  const { locale } = useLanguage()
  const currentAppLocale = AppLocale[locale]
  const isLoading = false

  moment.locale(locale)
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
      defaultRichTextElements={{
        b: (chunks) => <b>{chunks}</b>,
        p: (chunks) => <p>{chunks}</p>,
      }}
    >
      <IntlGlobalProvider>
        {!isLoading && (
          <ConfigProvider locale={currentAppLocale.antd}>
            <Switch>
              <Route exact path="/">
                <Redirect to={`${APP_PREFIX_PATH}/dashboard`} />
              </Route>
              <Route exact path={APP_PREFIX_PATH}>
                <Redirect to={`${APP_PREFIX_PATH}/dashboard`} />
              </Route>
              <Route path={AUTH_PREFIX_PATH}>
                <AuthLayout />
              </Route>
              <Route exact path={DEFAULT_PREFIX_PATH}>
                <Redirect to={`${DEFAULT_PREFIX_PATH}/legal-notice`} />
              </Route>
              <Route path={DEFAULT_PREFIX_PATH}>
                <DefaultLayout />
              </Route>
              <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
                <AppLayout location={location} />
              </RouteInterceptor>
              <RouteInterceptor path={ADMIN_PREFIX_PATH} isAuthenticated={token}>
                <AdminLayout location={location} />
              </RouteInterceptor>

              <Route path="*">
                <Redirect to={`${APP_PREFIX_PATH}`} />
              </Route>
            </Switch>
          </ConfigProvider>
        )}
      </IntlGlobalProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { token } = auth
  return { token }
}

export default withRouter(connect(mapStateToProps)(Views))
