export const SIGNIN = 'SIGNIN'
export const AUTHENTICATED = 'AUTHENTICATED'
export const SIGNOUT = 'SIGNOUT'
export const DELETE_USER = 'DELETE_USER'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const UID = 'uid'
export const ROLES = 'roles'
export const COMPANIES = 'companies'
export const CLIENT = 'client'
export const CLIENT_HISTORY_DATE = 'client_history_date'
export const CLIENT_DEFAULT_CURRENCY = 'client_default_currency'
export const ACCESS_TOKEN = 'access_token'
export const SOCIALS = 'socials'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'
