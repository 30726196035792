import { deleteUser } from 'firebase/auth'
import {
  auth,
  createUserWithEmailAndPassword,
  googleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'auth/FirebaseAuth'

const FirebaseService = {}

FirebaseService.signInEmailRequest = async (email, password) =>
  await signInWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.signOutRequest = async () =>
  await signOut(auth)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.signInGoogleRequest = async () =>
  await signInWithPopup(auth, googleAuthProvider)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.signUpEmailRequest = async (email, password) =>
  await createUserWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.deleteUser = async () => {
  await deleteUser(auth.currentUser)
    .then((user) => user)
    .catch((err) => err)
}

FirebaseService.sendPasswordResetEmail = async () =>
  await sendPasswordResetEmail(auth, auth.currentUser.email)
    .then(() => 'RESET_PASSWORD')
    .catch((err) => err)

export default FirebaseService
