import fetch from 'auth/FetchInterceptor'
import { auth } from 'auth/FirebaseAuth'

const JwtAuthService = {}

JwtAuthService.getRole = function () {
  return fetch({
    url: '/system/roles/current',
    method: 'GET',
  })
}

JwtAuthService.getIdToken = async function () {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await auth.currentUser.getIdToken()
        resolve(token)
      } else {
        resolve(null)
      }
    })
  })
}

JwtAuthService.removeTokens = function () {
  localStorage.clear()
  sessionStorage.clear()
  window.location.reload()
}

export default JwtAuthService
