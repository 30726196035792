import {APP_PREFIX_PATH} from 'configs/AppConfig'
import {
  AnalyticsSVG,
  ContentNavSVG,
  DashboardSVG,
  FacebookNav,
  InsightsSVG,
  AudienceSVG,
  InstagramNav,
  LinkedinNav,
  SnapchatNav,
  TikTokNav,
  YoutubeNav,
  DigitekaNav
} from '../assets/logos/icon'

const navigation = [
  {
    key: 'dashboard',
    title: 'headernav.menu.dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: import('views/app-views/dashboard-view'),
    headerTitle: 'headernav.menu.dashboard',
    onHeader: true,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    icon: DashboardSVG,
    socialIdentifier: undefined,
    submenu: []
  },
  {
    key: 'facebook',
    title: 'headernav.menu.facebook',
    onHeader: true,
    color: '#1877f2',
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    icon: FacebookNav,
    socialIdentifier: 'fb',
    submenu: [
      {
        key: 'facebookPosts',
        path: `${APP_PREFIX_PATH}/list/facebook`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/facebook'),
        headerTitle: 'page.meta.title.facebook.posts',
        parent: 'facebook',
        onHeader: true,
        icon: ContentNavSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'facebookPost',
        path: `${APP_PREFIX_PATH}/details/facebook/:id`,
        title: 'page.meta.title.facebook.post',
        component: import('views/app-views/details-view/facebook'),
        headerTitle: 'page.meta.title.facebook.posts',
        parent: 'facebook',
        onHeader: false,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'facebookAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/facebook`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/facebook'),
        parent: 'facebook',
        headerTitle: 'page.meta.title.facebook.analytics',
        onHeader: true,
        icon: AnalyticsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'benchmarkFacebookPosts',
        path: `${APP_PREFIX_PATH}/list/benchmark/facebook`,
        title: 'headernav.menu.benchmark.posts',
        component: import('views/app-views/list-view/benchmark/facebook'),
        headerTitle: 'page.meta.title.benchmark.facebook.posts',
        parent: 'facebook',
        onHeader: true,
        icon: ContentNavSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'facebookInsights',
        path: `${APP_PREFIX_PATH}/insights/facebook`,
        title: 'headernav.menu.insights',
        component: import('views/app-views/insights-view/facebook'),
        parent: 'facebook',
        headerTitle: 'page.meta.title.facebook.insights',
        onHeader: true,
        icon: InsightsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'facebookAudience',
        path: `${APP_PREFIX_PATH}/audience/facebook`,
        title: 'headernav.menu.audience',
        component: import('views/app-views/audience-view/facebook'),
        parent: 'facebook',
        headerTitle: 'page.meta.title.facebook.audience',
        onHeader: true,
        icon: AudienceSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'facebookTests',
        path: `${APP_PREFIX_PATH}/tests/facebook`,
        title: 'headernav.menu.tests',
        component: import('views/app-views/list-view/facebook'),
        parent: 'facebook',
        headerTitle: 'page.meta.title.facebook.tests',
        onHeader: true,
        icon: InsightsSVG,
        loopsiderOnly: true,
        rights: ['analyst', 'admin', 'sysadmin']
      }
    ]
  },
  {
    key: 'instagram',
    title: 'headernav.menu.instagram',
    onHeader: true,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: '#db1a8b',
    socialIdentifier: 'fb',
    icon: InstagramNav,
    submenu: [
      {
        key: 'instagramMedias',
        path: `${APP_PREFIX_PATH}/list/instagram`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/instagram'),
        parent: 'instagram',
        headerTitle: 'page.meta.title.instagram.medias',
        onHeader: true,
        icon: ContentNavSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'instagramMedia',
        path: `${APP_PREFIX_PATH}/details/instagram/:id`,
        title: 'page.meta.title.instagram.post',
        component: import('views/app-views/details-view/instagram'),
        parent: 'instagram',
        headerTitle: 'page.meta.title.instagram.medias',
        onHeader: false,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'instagramAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/instagram`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/instagram'),
        parent: 'instagram',
        headerTitle: 'page.meta.title.instagram.analytics',
        onHeader: true,
        icon: AnalyticsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      /*{
        key: 'benchmarkInstagramMedias',
        path: `${APP_PREFIX_PATH}/list/benchmark/instagram`,
        title: 'headernav.menu.benchmark.posts',
        component: import('views/app-views/list-view/benchmark/instagram'),
        headerTitle: 'page.meta.title.benchmark.facebook.posts',
        parent: 'instagram',
        onHeader: true,
        icon: ContentNavSVG,
        rights: ['dev']
      },*/
      {
        key: 'instagramInsights',
        path: `${APP_PREFIX_PATH}/insights/instagram`,
        title: 'headernav.menu.insights',
        component: import('views/app-views/insights-view/instagram'),
        parent: 'instagram',
        headerTitle: 'page.meta.title.instagram.insights',
        onHeader: true,
        icon: InsightsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'instagramStories',
        path: `${APP_PREFIX_PATH}/stories/instagram`,
        title: 'headernav.menu.stories',
        component: import('views/app-views/stories-view/instagram'),
        parent: 'instagram',
        headerTitle: 'page.meta.title.instagram.stories',
        onHeader: true,
        icon: ContentNavSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'instagramAudience',
        path: `${APP_PREFIX_PATH}/audience/instagram`,
        title: 'headernav.menu.audience',
        component: import('views/app-views/audience-view/instagram'),
        parent: 'instagram',
        headerTitle: 'page.meta.title.instagram.audience',
        onHeader: true,
        icon: AudienceSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
    ]
  },
  {
    key: 'tiktok',
    title: 'headernav.menu.tiktok',
    onHeader: true,
    socialIdentifier: 'tt',
    icon: TikTokNav,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: 'black',
    submenu: [
      {
        key: 'tiktokVideos',
        path: `${APP_PREFIX_PATH}/list/tiktok`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/tiktok'),
        parent: 'tiktok',
        headerTitle: 'page.meta.title.tiktok.videos',
        onHeader: true,
        icon: ContentNavSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'tiktokVideo',
        path: `${APP_PREFIX_PATH}/details/tiktok/:id`,
        title: 'page.meta.title.tiktok.post',
        component: import('views/app-views/details-view/tiktok'),
        parent: 'tiktok',
        headerTitle: 'page.meta.title.tiktok.videos',
        onHeader: false,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'tiktokAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/tiktok`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/tiktok'),
        parent: 'tiktok',
        headerTitle: 'page.meta.title.tiktok.analytics',
        onHeader: true,
        icon: AnalyticsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'tiktokInsights',
        path: `${APP_PREFIX_PATH}/insights/tiktok`,
        title: 'headernav.menu.insights',
        component: import('views/app-views/insights-view/tiktok'),
        parent: 'tiktok',
        headerTitle: 'page.meta.title.tiktok.insights',
        onHeader: true,
        icon: InsightsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'tiktokAudience',
        path: `${APP_PREFIX_PATH}/audience/tiktok`,
        title: 'headernav.menu.audience',
        component: import('views/app-views/audience-view/tiktok'),
        parent: 'tiktok',
        headerTitle: 'page.meta.title.tiktok.audience',
        onHeader: true,
        icon: AudienceSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
    ]
  },
  {
    key: 'youtube',
    title: 'headernav.menu.youtube',
    onHeader: true,
    socialIdentifier: 'yt',
    icon: YoutubeNav,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: 'black',
    submenu: [
      {
        key: 'youtubeVideos',
        path: `${APP_PREFIX_PATH}/list/youtube`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/youtube'),
        parent: 'youtube',
        headerTitle: 'page.meta.title.youtube.videos',
        onHeader: true,
        icon: ContentNavSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'youtubeAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/youtube`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/youtube'),
        parent: 'youtube',
        headerTitle: 'page.meta.title.youtube.analytics',
        onHeader: true,
        icon: AnalyticsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'benchmarkYoutubeVideos',
        path: `${APP_PREFIX_PATH}/list/benchmark/youtube`,
        title: 'headernav.menu.benchmark.posts',
        component: import('views/app-views/list-view/benchmark/youtube'),
        headerTitle: 'page.meta.title.benchmark.youtube.videos',
        parent: 'youtube',
        onHeader: true,
        icon: ContentNavSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'youtubeVideo',
        path: `${APP_PREFIX_PATH}/details/youtube/:id`,
        title: 'page.meta.title.youtube.video',
        component: import('views/app-views/details-view/youtube'),
        parent: 'youtube',
        headerTitle: 'page.meta.title.youtube.videos',
        onHeader: false,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'youtubeInsights',
        path: `${APP_PREFIX_PATH}/insights/youtube`,
        title: 'headernav.menu.insights',
        component: import('views/app-views/insights-view/youtube'),
        parent: 'youtube',
        headerTitle: 'page.meta.title.youtube.insights',
        onHeader: true,
        icon: InsightsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
      // {
      //   key: 'youtubeAudience',
      //   path: `${APP_PREFIX_PATH}/audience/youtube`,
      //   title: 'headernav.menu.audience',
      //   component: import('views/app-views/audience-view/youtube'),
      //   parent: 'youtube',
      //   headerTitle: 'page.meta.title.youtube.audience',
      //   onHeader: true,
      //   icon: AudienceSVG,
      //   loopsiderOnly: true,
      //   rights: ['dev']
      // },
    ]
  },
  {
    key: 'snapchat',
    title: 'headernav.menu.snapchat',
    onHeader: true,
    socialIdentifier: 'sc',
    icon: SnapchatNav,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    color: 'black',
    submenu: [
      {
        key: 'snapchatPosts',
        path: `${APP_PREFIX_PATH}/list/snapchat`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/snapchat'),
        parent: 'snapchat',
        headerTitle: 'page.meta.title.snapchat.content',
        onHeader: true,
        icon: ContentNavSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'snapchatPost',
        path: `${APP_PREFIX_PATH}/details/snapchat/:id`,
        title: 'page.meta.title.snapchat.post',
        component: import('views/app-views/details-view/snapchat'),
        parent: 'snapchat',
        headerTitle: 'page.meta.title.snapchat.content',
        onHeader: false,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'snapchatAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/snapchat`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/snapchat'),
        parent: 'snapchat',
        headerTitle: 'page.meta.title.snapchat.analytics',
        onHeader: true,
        icon: AnalyticsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'snapchatInsights',
        path: `${APP_PREFIX_PATH}/insights/snapchat`,
        title: 'headernav.menu.insights',
        component: import('views/app-views/insights-view/snapchat'),
        parent: 'snapchat',
        headerTitle: 'page.meta.title.snapchat.insights',
        onHeader: true,
        icon: InsightsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
      /*{
        key: 'snapchatAudience',
        path: `${APP_PREFIX_PATH}/audience/snapchat`,
        title: 'headernav.menu.audience',
        component: import('views/app-views/audience-view/snapchat'),
        parent: 'snapchat',
        headerTitle: 'page.meta.title.snapchat.audience',
        onHeader: true,
        icon: AudienceSVG,
        loopsiderOnly: true,
        rights: ['dev']
      },*/
    ]
  },
  {
    key: 'digiteka',
    title: 'headernav.menu.digiteka',
    onHeader: true,
    socialIdentifier: 'dk',
    icon: DigitekaNav,
    rights: ['admin', 'sysadmin'],
    color: 'black',
    submenu: [
      {
        key: 'digitekaVideos',
        path: `${APP_PREFIX_PATH}/list/digiteka`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/digiteka'),
        parent: 'digiteka',
        headerTitle: 'page.meta.title.digiteka.videos',
        onHeader: true,
        icon: ContentNavSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'digitekaAnalyticsAudience',
        path: `${APP_PREFIX_PATH}/analytics/digiteka/audience`,
        title: 'headernav.menu.audience',
        component: import('views/app-views/analytics-view/digiteka'),
        parent: 'digiteka',
        headerTitle: 'page.meta.title.digiteka.analytics.audience',
        onHeader: true,
        icon: AnalyticsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },
      {
        key: 'digitekaAnalyticsMonetization',
        path: `${APP_PREFIX_PATH}/analytics/digiteka/monetization`,
        title: 'headernav.menu.monetization',
        component: import('views/app-views/analytics-view/digiteka'),
        parent: 'digiteka',
        headerTitle: 'page.meta.title.digiteka.analytics.monetization',
        onHeader: true,
        icon: AnalyticsSVG,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      },

      {
        key: 'digitekaVideo',
        path: `${APP_PREFIX_PATH}/details/digiteka/:id`,
        title: 'page.meta.title.digiteka.video',
        component: import('views/app-views/details-view/digiteka'),
        parent: 'digiteka',
        headerTitle: 'page.meta.title.digiteka.videos',
        onHeader: false,
        rights: ['user', 'analyst', 'admin', 'sysadmin']
      }
    ]
  },
  /*{
    key: 'linkedin',
    title: 'headernav.menu.linkedin',
    onHeader: true,
    socialIdentifier: 'li',
    icon: LinkedinNav,
    rights: ['dev'],
    color: 'black',
    submenu: [
      {
        key: 'linkedinPosts',
        path: `${APP_PREFIX_PATH}/list/linkedin`,
        title: 'headernav.menu.posts',
        component: import('views/app-views/list-view/linkedin'),
        parent: 'linkedin',
        headerTitle: 'page.meta.title.linkedin.posts',
        onHeader: true,
        icon: ContentNavSVG,
        rights: ['dev']
      },
      {
        key: 'linkedinAnalytics',
        path: `${APP_PREFIX_PATH}/analytics/linkedin`,
        title: 'headernav.menu.analytics',
        component: import('views/app-views/analytics-view/linkedin'),
        parent: 'linkedin',
        headerTitle: 'page.meta.title.linkedin.analytics',
        onHeader: true,
        icon: AnalyticsSVG,
        rights: ['dev']
      }
    ]
  },*/
  /*{
    key: 'campaign-view',
    title: 'headernav.menu.campaign',
    path: `${APP_PREFIX_PATH}/campaigns`,
    component: import('views/app-views/campaign-view'),
    headerTitle: 'headernav.menu.campaign',
    onHeader: process.env.REACT_APP_NODE_ENV === 'production' ? false : true,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    icon: CampaignNavSVG,
    submenu: [],
    socialIdentifier: undefined
  },*/
  {
    key: 'similars',
    title: 'headernav.menu.similars',
    path: `${APP_PREFIX_PATH}/list/similars`,
    component: import('views/app-views/list-view/similars'),
    headerTitle: 'headernav.menu.similars',
    onHeader: false,
    rights: ['user', 'analyst', 'admin', 'sysadmin'],
    icon: null,
    socialIdentifier: undefined,
    submenu: []
  }
]

export default navigation
