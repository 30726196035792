import { initializeApp } from 'firebase/app'
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import firebaseConfig from 'configs/FirebaseConfig'

const app = initializeApp(firebaseConfig)

// firebase utils
const db = getFirestore(app)
const auth = getAuth(app)
const { currentUser } = auth
const googleAuthProvider = new GoogleAuthProvider()
const analytics = getAnalytics(app)

export {
  db,
  auth,
  currentUser,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  deleteUser,
  sendPasswordResetEmail,
  googleAuthProvider,
  analytics,
}
