import fetch from 'auth/FetchInterceptor'

const SystemRolesService = {}

SystemRolesService.getRoles = function (params) {
  return fetch({
    url: `/system/roles`,
    method: 'GET',
    params,
  })
}

SystemRolesService.updateRole = function (params) {
  return fetch({
    url: `/system/roles/${params.id}`,
    method: 'PUT',
    params,
  })
}

SystemRolesService.createRole = function (params) {
  return fetch({
    url: `/system/roles`,
    method: 'POST',
    params,
  })
}

SystemRolesService.removeRole = function (params) {
  return fetch({
    url: `/system/roles/${params.role_id}`,
    method: 'DELETE',
  })
}

SystemRolesService.resendEmailRegister = function (params) {
  return fetch({
    url: `/system/roles/resend-email-register/${params.role_id}`,
    method: 'POST',
  })
}

// Request Offline
SystemRolesService.getRoleByEmailToken = function (params) {
  return fetch({
    url: `/system/roles/token/${params.email_token}`,
    method: 'GET',
  })
}

// Request Offline
SystemRolesService.updateRoleByRegister = function (params) {
  return fetch({
    url: `/system/roles/token/${params.email_token}`,
    method: 'PUT',
    params,
  })
}

// Request Offline
SystemRolesService.forgotPassword = function (params) {
  return fetch({
    url: `/system/roles/forgot-password`,
    method: 'PUT',
    params,
  })
}

// Request Offline
SystemRolesService.newResetPassword = function (params) {
  return fetch({
    url: `/system/roles/update-password/`,
    method: 'PUT',
    params,
  })
}

// Request Offline
SystemRolesService.updatePassword = function (params) {
  return fetch({
    url: `/system/roles/reset-password/token/${params.email_token}`,
    method: 'PUT',
    params,
  })
}

export default SystemRolesService
