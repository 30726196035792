import React, {useEffect, useState} from 'react'
import {Layout} from 'antd'
import {connect} from 'react-redux'
import SidebarNavAdmin from 'components/layout-components/SideBarAdmin'
import PageHeader from 'components/layout-components/PageHeader/PageHeader'
import Footer from 'components/layout-components/Footer/Footer'
import AdminViews from 'views/admin-views'
import navigationConfig from 'configs/AdminNavigationConfig'
import utils from 'utils'

const {Content} = Layout

export const AdminLayout = ({location}) => {
  const [collapsed, setCollapsed] = useState(false)
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)

  useEffect(() => {
    if (window.localStorage.getItem('navCollapsed') === null) {
      window.localStorage.setItem('navCollapsed', false)
    } else {
      setCollapsed(window.localStorage.getItem('navCollapsed') === 'false' ? false : true)
    }
  }, [])

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
    window.localStorage.setItem('navCollapsed', !collapsed)
  }

  const containerMarginLeft = currentRouteInfo?.key === 'mailReport' ? '0' : collapsed ? '80px' : '240px'

  return (
    <Layout>
      <Layout>
        {currentRouteInfo?.key !== 'mailReport' && (
          <SidebarNavAdmin
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
            location={location}
            navigation={navigationConfig}
          />
        )}
        <Layout className="app-container" style={{marginLeft: containerMarginLeft, backgroundColor: '#0C0B0B'}}>
          <Layout className="app-layout" style={{backgroundColor: '#ebebeb'}}>
            <div style={{paddingTop: '20px', padding: '0'}} className="app-content">
              <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
              <div className="admin-container">
                <div className="admin-content">
                  <Content>
                    <AdminViews />
                  </Content>
                </div>
              </div>
            </div>
            {utils.checkUserAccess(['sysadmin']) && currentRouteInfo?.key !== 'mailReport' ? <Footer /> : <></>}
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = ({theme}) => {
  const {navCollapsed, navType, locale} = theme
  return {navCollapsed, navType, locale}
}

export default connect(mapStateToProps)(React.memo(AdminLayout))
