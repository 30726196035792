import React from 'react'
import { FormattedMessage } from 'react-intl'
import AppBreadcrumb from 'components/layout-components/Breadcrumb'

export const PageHeader = ({ title, display }) =>
  display ? (
    <div className="app-page-header">
      <h3 className="mb-0 mr-3 font-weight-semibold">
        <FormattedMessage id={title || 'home'} />
      </h3>
      <AppBreadcrumb />
    </div>
  ) : null

export default PageHeader
