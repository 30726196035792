import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import {ADMIN_PREFIX_PATH, APP_PREFIX_PATH} from 'configs/AppConfig'
import navigationConfig from 'configs/NavigationConfig'
import utils from 'utils'

export const AppViews = () => {
  let routes = []
  for (const parent of navigationConfig) {
    if (parent.submenu.length > 0) {
      routes = [...routes, ...parent.submenu.map((child) => child)]
    } else {
      routes = [...routes, parent]
    }
  }

  if (utils.checkUserAccess(['corrector'])) {
    return <Redirect to={`${ADMIN_PREFIX_PATH}/categories/corrections/facebook`} />
  }

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route exact path={`${APP_PREFIX_PATH}/facebook`}>
          <Redirect to={`${APP_PREFIX_PATH}/list/facebook`} />
        </Route>
        <Route exact path={`${APP_PREFIX_PATH}/instagram`}>
          <Redirect to={`${APP_PREFIX_PATH}/list/instagram`} />
        </Route>
        <Route exact path={`${APP_PREFIX_PATH}/tiktok`}>
          <Redirect to={`${APP_PREFIX_PATH}/list/tiktok`} />
        </Route>
        <Route exact path={`${APP_PREFIX_PATH}/youtube`}>
          <Redirect to={`${APP_PREFIX_PATH}/list/youtube`} />
        </Route>
        <Route
          exact
          path={`${APP_PREFIX_PATH}/politique_confidentialite.pdf`}
          component={() => {
            window.location.href = `${utils.getBucketUrlDocs('politique_confidentialite.pdf', 'french')}`
            return null
          }}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/cguv.pdf`}
          component={() => {
            window.location.href = `${utils.getBucketUrlDocs('cguv.pdf', 'french')}`
            return null
          }}
        />
        {routes.map(
          (route) =>
            utils.checkUserAccess(route.rights) && (
              <Route key={route.key} path={route.path} component={lazy(() => route.component)} />
            )
        )}

        {/* {utils.checkUserAccess(['user', 'sysadmin']) && (
           <Route
           path={`${APP_PREFIX_PATH}/admin/pages`}
           component={lazy(() => import('views/app-views/admin/pages'))}
         />
          <Route
            path={`${APP_PREFIX_PATH}/admin/categories`}
            component={lazy(() => import('views/app-views/admin/categories'))}
          />
        )} */}

        <Route
          path={`${APP_PREFIX_PATH}/not-found`}
          component={lazy(() => import('views/app-views/error-view/NotFound'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/unexpected`}
          component={lazy(() => import('views/app-views/error-view/Unexpected'))}
        />
        <Route path="*">
          <Redirect to={`${APP_PREFIX_PATH}/not-found`} />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)
