import antdEnUS from 'antd/es/locale/en_US'
import moment from 'moment'
import enMsg from '../locales/en_US.json'

const EnLang = {
  antd: antdEnUS,
  locale: 'en-US',
  messages: {
    ...enMsg,
  },
}

moment.locale('en', {
  calendar: {
    sameDay: '[Today] LT',
    lastDay: '[Yesterday] LT',
    lastWeek: 'L LT',
    sameElse: 'L LT',
  },
})

export default EnLang
