import * as Sentry from '@sentry/react'
import ErrorBoundary from 'components/util-components/ErrorBoundary'
import {env} from 'configs/EnvironmentConfig'
import {LanguageProvider} from 'contexts/LanguageContext'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {Provider} from 'react-redux'
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom'
import {QueryParamProvider} from 'use-query-params'
import store from './redux/store'
import Views from './views'
import React from 'react'

Sentry.init({
  environment: env.nodeEnv,
  enabled: !['development'].includes(env.nodeEnv),
  dsn: 'https://cdb7195178cff1a12a5356801e51b47e@o4505862258622464.ingest.sentry.io/4505862260654080',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.5 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      cacheTime: 1000 * 60 * 5, // 5 minutes
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      suspense: false,
      prefetchQuery: 'all'
    },
    mutations: {
      retry: 2
    }
  }
})

const App = () => (
  <QueryClientProvider client={queryClient}>
    <div className="App">
      <Provider store={store}>
        <LanguageProvider>
          <Router>
            <ErrorBoundary>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Switch>
                  <Route path="/" component={Views} />
                </Switch>
              </QueryParamProvider>
            </ErrorBoundary>
          </Router>
        </LanguageProvider>
      </Provider>
    </div>
    <ReactQueryDevtools />
  </QueryClientProvider>
)

export default App
