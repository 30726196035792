export const FILTER_SIMILARS_ACCOUNTS = 'filter-similars-accounts'
export const FILTER_SIMILARS_WORKSPACES = 'filter-similars-workspaces'
export const FILTER_DASHBOARD_GROUP_WORKSPACES = 'filter-dashboard-group-workspaces'
export const FILTER_DASHBOARD_WORKSPACES = 'filter-dashboard-workspaces'
export const FILTER_FACEBOOK_PAGES = 'filter-facebook-posts-pages'
export const FILTER_FACEBOOK_TEST_PAGES = 'filter-facebook-test-pages'
export const FILTER_FACEBOOK_BENCHMARK_ACCOUNTS = 'filter-facebook-benchmark-account'
export const FILTER_FACEBOOK_BENCHMARK_LIST = 'filter-facebook-benchmark-list'
export const FILTER_INSTAGRAM_ACCOUNTS = 'filter-instagram-account'
export const FILTER_INSTAGRAM_BENCHMARK_LIST = 'filter-instagram-benchmark-list'
export const FILTER_INSTAGRAM_BENCHMARK_ACCOUNTS = 'filter-instagram-benchmark-account'
export const FILTER_TIKTOK_ACCOUNTS = 'filter-tiktok-account'
export const FILTER_INSTAGRAM_STORIES_ACCOUNTS = 'filter-instagram-stories-account'
export const FILTER_YOUTUBE_ACCOUNTS = 'filter-youtube-account'
export const FILTER_YOUTUBE_BENCHMARK_LIST = 'filter-youtube-benchmark-list'
export const FILTER_YOUTUBE_BENCHMARK_ACCOUNTS = 'filter-youtube-benchmark-account'
export const FILTER_SNAPCHAT_ACCOUNTS = 'filter-snapchat-account'
export const FILTER_LINKEDIN_ACCOUNTS = 'filter-linkedin-account'
export const FILTER_BRANDS_GROUP_LIST = 'filter-brand-group-list'
export const FILTER_DIGITEKA_COMPANIES = 'filter-digiteka-companies'
export const FILTER_DIGITEKA_CATALOGS = 'filter-digiteka-catalogs'
export const FILTER_DIGITEKA_SITES = 'filter-digiteka-sites'
export const FILTER_DIGITEKA_VIEW = 'filter-digiteka-view'
