import React, {useEffect, useState} from 'react'
import {Layout} from 'antd'
import {connect} from 'react-redux'
import PageHeader from 'components/layout-components/PageHeader/PageHeader'
import AppViews from 'views/app-views'
import navigationConfig from 'configs/NavigationConfig'
import utils from 'utils'
import SiderNavBarApp from '../../components/layout-components/SideBarApp'

const {Content} = Layout

export const AppLayout = ({location}) => {
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    if (window.localStorage.getItem('navCollapsed') === null) {
      window.localStorage.setItem('navCollapsed', false)
    } else {
      setCollapsed(window.localStorage.getItem('navCollapsed') === 'false' ? false : true)
    }
  }, [])

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
    window.localStorage.setItem('navCollapsed', !collapsed)
  }

  return (
    <Layout>
      <Layout>
        <SiderNavBarApp
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
          location={location}
          navConfig={navigationConfig}
        />
        <Layout
          className="app-container"
          style={collapsed ? {marginLeft: '80px', backgroundColor: '#0C0B0B'} : {backgroundColor: '#0C0B0B'}}
        >
          <Layout className="app-layout" style={{backgroundColor: '#ebebeb'}}>
            <div className="app-content">
              <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
              <Content>
                <AppViews />
              </Content>
            </div>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = ({theme}) => {
  const {navCollapsed, navType, locale} = theme
  return {navCollapsed, navType, locale}
}

export default connect(mapStateToProps)(React.memo(AppLayout))
