import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import {ADMIN_PREFIX_PATH, APP_PREFIX_PATH} from 'configs/AppConfig'
import adminNavigationConfig from 'configs/AdminNavigationConfig'
import utils from 'utils'

export const AdminViews = () => {
  let adminRoutes = []
  for (const parent of adminNavigationConfig) {
    if (parent.submenu?.length) {
      adminRoutes = [...adminRoutes, ...parent.submenu.map((child) => child)]
    } else if (parent.component) {
      adminRoutes = [...adminRoutes, parent]
    }
  }

  if (utils.checkUserAccess(['corrector'])) {
    return (
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          <Route exact path={`${ADMIN_PREFIX_PATH}/`}>
            <Redirect to={`${ADMIN_PREFIX_PATH}/categories/corrections/facebook`} />
          </Route>
          {adminRoutes.map((adminRoute) => (
            <Route key={adminRoute.key} path={adminRoute.path} component={lazy(() => adminRoute.component)} />
          ))}
        </Switch>
      </Suspense>
    )
  } else if (utils.checkUserAccess(['admin', 'sysadmin'])) {
    return (
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          <Route exact path={`${ADMIN_PREFIX_PATH}/`}>
            <Redirect to={`${ADMIN_PREFIX_PATH}/users/companies`} />
          </Route>
          {adminRoutes.map((adminRoute) => (
            <Route key={adminRoute.key} path={adminRoute.path} component={lazy(() => adminRoute.component)} />
          ))}
        </Switch>
      </Suspense>
    )
  } else {
    return (
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          <Route path="*">
            <Redirect to={`${APP_PREFIX_PATH}/not-found`} />
          </Route>
        </Switch>
      </Suspense>
    )
  }
}

export default React.memo(AdminViews)
