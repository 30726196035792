import {
  ACCESS_TOKEN,
  AUTHENTICATED,
  HIDE_AUTH_MESSAGE,
  ROLES,
  SHOW_AUTH_MESSAGE,
  SHOW_LOADING,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  UID,
} from '../constants/Auth'

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(ACCESS_TOKEN),
  uid: localStorage.getItem(UID),
  role: localStorage.getItem(ROLES),
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        token: action.token,
        uid: action.uid,
        role: action.role,
      }
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      }
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      }
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: '/',
        loading: false,
      }
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.access_token,
        uid: action.uid,
        roles: action.roles,
      }
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
        uid: action.uid,
        role: action.role,
      }
    }
    case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
        uid: action.uid,
        role: action.role,
      }
    }
    default:
      return state
  }
}

export default auth
