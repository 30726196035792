import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { notification } from 'antd'
import utils from 'utils'

export default function Footer() {
  const intl = useIntl()

  const copyAccessTokenToClipboard = (e) => {
    e.preventDefault()
    navigator.clipboard.writeText(localStorage.getItem('access_token'))
    notification.info({
      key: `admin.token.copy`,
      message: intl.formatMessage({
        id: 'footer.admin.usertoken.copy.notification.message',
      }),
    })
  }

  return (
    <footer className="footer">
      {utils.checkUserAccess(['sysadmin']) && (
        <div className="copy-user-token-button">
          <a href="#_" onClick={copyAccessTokenToClipboard}>
            <FormattedMessage id="footer.admin.usertoken.copy.button.label" />
          </a>
        </div>
      )}
    </footer>
  )
}
