import {useQuery} from 'react-query'
import JwtAuthService from 'services/JwtAuthService'
import {
  ACCESS_TOKEN,
  CLIENT,
  CLIENT_DEFAULT_CURRENCY,
  CLIENT_HISTORY_DATE,
  COMPANIES,
  ROLES,
  SOCIALS
} from 'redux/constants/Auth'
import {FILTER_DASHBOARD_WORKSPACES} from 'constants/StorageConstant'
import * as Sentry from '@sentry/react'

const useIdToken = () =>
  useQuery(
    ['IdToken'],
    async () => {
      const token = await JwtAuthService.getIdToken()
      if (token) {
        localStorage.setItem(ACCESS_TOKEN, token)
      }

      return token
    },
    {refetchOnWindowFocus: false, cacheTime: 100000}
  )

const useCurrentUser = () =>
  useQuery(
    ['currentUser'],
    async () => {
      const user = await JwtAuthService.getRole()

      Sentry.setUser({
        name: user.data.name,
        email: user.data.email,
        client_id: user.data.client_id,
        companies: user.data.companies,
        roles: user.data.roles
      })

      localStorage.setItem(ROLES, JSON.stringify(user.data.roles))
      localStorage.setItem(COMPANIES, JSON.stringify(user.data.companies))
      localStorage.setItem(SOCIALS, JSON.stringify(user.data.socialsIds))
      localStorage.setItem(CLIENT, JSON.stringify(user.data.client_id))
      localStorage.setItem(CLIENT_HISTORY_DATE, user.data.client_history_date)
      localStorage.setItem(CLIENT_DEFAULT_CURRENCY, user.data.client_default_currency)

      // Only first login, then the filter lives its life from dashboard-view
      if (localStorage.getItem(FILTER_DASHBOARD_WORKSPACES) === null) {
        localStorage.setItem(FILTER_DASHBOARD_WORKSPACES, user.data.companies)
      }
      return user
    },
    {refetchOnWindowFocus: false}
  )

export default {
  useIdToken,
  useCurrentUser
}
