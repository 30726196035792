import React from 'react'
import { Route, Switch } from 'react-router-dom'
import DefaultViews from 'views/default-views'

export const DefaultLayout = () => (
  <div className="default-container">
    <Switch>
      <Route path="" component={DefaultViews} />
    </Switch>
  </div>
)

export default DefaultLayout
