import axios from 'axios'
import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const LanguageContext = createContext()

export const useLanguage = () => useContext(LanguageContext)

export const getBrowserLanguage = () => {
  const language = navigator.language.split('-')[0]
  return language === 'fr' || language === 'en' ? language : 'en'
}

export const setPreferredLanguage = (language) => {
  localStorage.setItem('preferredLanguage', language)
}

export const getPreferredLanguage = () => localStorage.getItem('preferredLanguage') || getBrowserLanguage()

export const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState(window.location.pathname.includes('MailReport') ? 'fr' : getPreferredLanguage())

  const handleLanguageChange = (newLocale) => {
    setLocale(newLocale)
    setPreferredLanguage(newLocale)
    axios.defaults.headers.common['Accept-Language'] = newLocale
  }

  useEffect(() => {
    axios.defaults.headers.common['Accept-Language'] = locale
  }, [locale])

  return <LanguageContext.Provider value={{ locale, handleLanguageChange }}>{children}</LanguageContext.Provider>
}
