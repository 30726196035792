import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { DEFAULT_PREFIX_PATH } from 'configs/AppConfig'

export const DefaultViews = () => (
  <Suspense fallback={<Loading cover="page" />}>
    <Switch>
      <Route path={`${DEFAULT_PREFIX_PATH}/legal-notice`} component={lazy(() => import(`./info/legal-notice`))} />
      <Route path={`${DEFAULT_PREFIX_PATH}/cgu`} component={lazy(() => import(`./info/cgu`))} />
    </Switch>
  </Suspense>
)

export default DefaultViews
