import React from 'react'
import AuthHooks from 'hooks/Auth'
import NavProfile from 'components/layout-components/Header/NavProfile'

const NavPanel = ({collapsed}) => {
  const { data: user, isSuccess: userIsSuccess } = AuthHooks.useCurrentUser()
  return <>{userIsSuccess && <NavProfile collapsed={collapsed} user={user.data} />}</>
}

export default NavPanel
